<template>
  <div class="menu-nav" :id="item.title">
    <div class="menu-section w-100 ma-0 menu-title">
      <inline-svg width="40px" height="40px" class="px-1" :src="item.svg" />
      <h2 class="font-weight-bolder pt-1">{{ $t(item.title) }}</h2>
    </div>
    <v-list class="mt-4 px-5 poppins font-weight-medium">
      <template v-for="group in item.entities">
        <v-list-group v-if="group?.items" :key="group.title" no-action>
          <template v-if="group.items.length" v-slot:activator>
            <v-list-item-content>
              <v-list-item-title
                v-text="$t(group.title)"
                class="menu-item text-dark-50 font-weight-bolder"
              />
            </v-list-item-content>
          </template>

          <template v-if="group?.items">
            <v-list-item
              v-for="child in group.items"
              :key="child.title"
              :to="{ name: child.name }"
              exact
              class="menu-link"
              :class="{ 'bg-light-info': activeSubmenu === child.name }"
              @click="
                () => {
                  $router.push({ name: child.name });
                  activeSubmenu = child.name;
                  hideSideBar();
                }
              "
            >
              <span class="menu-text ml-3">
                {{ $t(child.title) }}
              </span>
            </v-list-item>
          </template>
        </v-list-group>
        <v-list-item
          v-else
          :key="group.title"
          :to="{ name: group.name }"
          exact
          class="menu-link"
          :class="{ 'bg-light-info': activeSubmenu === group.name }"
          @click="
            () => {
              $router.push({ name: group.name });
              activeSubmenu = group.name;
              hideSideBar();
            }
          "
        >
          <span class="menu-text">
            {{ $t(group.title) }}
          </span>
        </v-list-item>
      </template>
    </v-list>
  </div>
</template>

<script>
import eventBus from "@/core/services/eventBus";
export default {
  name: "NaviDropDown",
  props: ["item"],
  data: () => ({
    activeSubmenu: null,
  }),
  methods: {
    hideSideBar() {
      eventBus.$emit("toggleAside");
    },
  },
};
</script>
