<template>
  <div class="topbar">
    <WarehouseSelect v-if="showWarehouseDropdown" />

    <!--begin: User Bar -->
    <KTQuickUser v-if="getCurrentUser"></KTQuickUser>
    <!--end: User Bar -->
  </div>
</template>

<script>
import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";
import WarehouseSelect from "@/view/layout/extras/offcanvas/WarehouseSelect.vue";

export default {
  name: "KTTopbar",
  data() {
    return {
      showWarehouseDropdown: false,
    };
  },
  components: {
    KTQuickUser,
    WarehouseSelect,
  },
  methods: {
    checkRouteChange(route) {
      if (
        route === "/receiving" ||
        route === "/putaway_task" ||
        route === "/picking" ||
        route === "/packing" ||
        route === "/qc" ||
        route === "/packing" ||
        route === "/shipping"
      ) {
        this.showWarehouseDropdown = true;
        return;
      }
      this.showWarehouseDropdown = false;
    },
  },
  computed: {
    getCurrentUser() {
      return this.$store.getters.currentUser.data;
    },
  },
  watch: {
    $route(to) {
      this.checkRouteChange(to.path);
    },
  },
  beforeMount() {
    this.checkRouteChange(this.$route.path);
  },
};
</script>
