<template>
  <!-- begin:: Footer -->
  <div
    class="footer bg-white py-4 d-flex flex-lg-column poppins position-fixed bottom-0 w-100"
    id="kt_footer"
  >
    <div
      class="d-flex align-content-center"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <a
        href="https://iqfulfillment.com"
        target="_blank"
        class="text-dark-75 poppins d-flex align-items-center"
      >
        <!-- <span> IQ Fulfillment</span> -->
        <img
          src="/img/IQ-FULFILLMENT-LOGO-PURPLE.png"
          style="width: 106px; margin-top: 4px"
        />
        <span
          class="poppins mx-2"
          style="font-size: 15px; margin-top: 6px; font-weight: 500"
          >Powered by:
        </span>
        <img width="64" src="/img/IQ-Hybrid-logo.png" style="margin-top: 4px" />
      </a>
      <span
        style="
          font-size: 19px;
          margin-top: 6px;
          margin-left: 5px;
          margin-right: 5px;
        "
        >&copy;</span
      >
      <span
        class="font-weight-bold mx-0"
        style="font-size: 14px; margin-top: 10px"
      >
        {{ new Date().getFullYear() }}
      </span>
    </div>
  </div>
  <!-- end:: Footer -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTFooter",
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    },
  },
};
</script>
